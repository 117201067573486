import { Layout } from 'components';
import React from 'react';

export default function Categories({}) {
  return (
    <Layout>
      <div className="container mx-auto">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>Blog Categories</div>
      </div>
    </Layout>
  );
}
